import React, { useCallback } from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import DataClassificationLevel from '@atlassian/data-classification-level';

import {
	ErrorDisplay,
	Attribution,
	withTransparentErrorBoundary,
} from '@confluence/error-boundary';
import { useSpaceId } from '@confluence/space-utils';
import { useSessionData } from '@confluence/session-data';

import type { SpaceClassificationMetadata } from '../hooks/useDefaultAndSpaceClassificationLevels';
import type { ContentClassificationMetadata } from '../hooks/useContentClassification';
import { useIsDataClassificationEnabledForOrg } from '../hooks/useIsDataClassificationEnabledForOrg';
import { ErrorState } from '../constants/ErrorState';

import { ClassificationLevelError } from './ClassificationLevelError';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomSitesContentClassificationTagWrapper = styled.div({
	marginTop: token('space.050', '4px'),
});

type TagProps = {
	contentId: string;
	contentType: string | null;
	isEditMode?: boolean;
	customSitesTitleComponent?: boolean;
	contentClassificationMetadata?: ContentClassificationMetadata;
	spaceClassificationMetadata?: SpaceClassificationMetadata;
	renderPopupComponent?: (closePopup: () => void) => React.ReactNode;
};

const messages = defineMessages({
	primaryErrorText: {
		id: 'data-classification.content-classification-tag.error-primary-text',
		defaultMessage: 'Unable to load all page data.',
		description: 'Primary text for classification tag loading error',
	},
	secondaryErrorText: {
		id: 'data-classification.content-classification-tag.error-secondary-text',
		defaultMessage: 'Try and reload the page.',
		description: 'Secondary text for classification tag loading error',
	},
	tooltipMessage: {
		id: 'data-classification.content-classification.tag.tooltip',
		defaultMessage: 'This {contentType} is classified as {classification}',
		description: 'Tooltip text for classification',
	},
});

const ContentClassificationTagComponent = ({
	contentType,
	contentClassificationMetadata,
	isEditMode = false,
	customSitesTitleComponent = false,
	renderPopupComponent,
}: TagProps) => {
	const ContentClassificationTagWrapper = customSitesTitleComponent
		? CustomSitesContentClassificationTagWrapper
		: React.Fragment;

	const intl = useIntl();
	const { cloudId, orgId } = useSessionData();
	const spaceId = useSpaceId();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { error: dataClassificationEnabledForOrgError } = useIsDataClassificationEnabledForOrg();

	const handlePopupShown = useCallback(() => {
		if (!isEditMode) {
			return;
		}

		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'dataClassificationEditPopup',
				action: 'viewed',
				actionSubject: 'popup',
				actionSubjectId: 'editorTitleDataClassificationPopup',
				attributes: {
					cloudId,
					spaceId,
					orgId,
				},
			},
		}).fire();
	}, [isEditMode, createAnalyticsEvent, cloudId, spaceId, orgId]);

	if (!contentClassificationMetadata) {
		return null;
	}

	const {
		classification: currentClassification,
		errorState,
		contentLevelError,
		spaceLevelError,
		shouldDisplayClassification,
	} = contentClassificationMetadata;

	// Two error handling cases resulting in early returns:
	// 1. If there's no classification to render, return null
	if (errorState === ErrorState.NULL) {
		return null;
		// 2. If there is an unhandled error (a non-404 error), return error message with error displays
	} else if (errorState === ErrorState.MESSAGE) {
		return (
			<ContentClassificationTagWrapper data-testid="data-classification-level-error">
				{contentLevelError ? <ErrorDisplay error={contentLevelError} /> : null}
				{spaceLevelError ? <ErrorDisplay error={spaceLevelError} /> : null}
				<ClassificationLevelError
					primaryText={messages.primaryErrorText}
					secondaryText={messages.secondaryErrorText}
					isTagDisplayError
				/>
			</ContentClassificationTagWrapper>
		);
	}

	// Return error display for when there is an error fetching if data classification is disabled for org
	if (dataClassificationEnabledForOrgError) {
		return (
			<ContentClassificationTagWrapper data-testid="data-classification-level-error">
				<ErrorDisplay error={dataClassificationEnabledForOrgError} />
			</ContentClassificationTagWrapper>
		);
	}

	// If data classification is completely turned off (or we're dealing with
	// non-published classifications) we don't want to display anything (even errors).
	if (!shouldDisplayClassification || !currentClassification) {
		return null;
	}

	// Classification is enabled, the classification status is published, and
	// we haven't encountered any errors. Display the classification to the user!
	return (
		<ContentClassificationTagWrapper data-testid="data-classification-level">
			<DataClassificationLevel
				testId="data-classification-level"
				name={currentClassification.name}
				guideline={currentClassification.guideline}
				tooltip={intl.formatMessage(messages.tooltipMessage, {
					contentType,
					classification: currentClassification.name,
				})}
				color={currentClassification.color}
				isPopupDisabled={!currentClassification.guideline && !isEditMode}
				renderPopupComponent={renderPopupComponent || undefined}
				onPopupShown={handlePopupShown}
			/>
		</ContentClassificationTagWrapper>
	);
};

const ContentClassificationTagGetter: FC<TagProps> = (props) => {
	return <ContentClassificationTagComponent {...props} />;
};

const ContentClassificationTagWithErrorBoundary = withTransparentErrorBoundary({
	attribution: Attribution.DLP,
})(ContentClassificationTagGetter);

export const ContentClassificationTag: FC<TagProps> = (props) => (
	<ContentClassificationTagWithErrorBoundary {...props} />
);
