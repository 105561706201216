import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { TeammatePresenceLivePagePlaceholder } from './TeammatePresenceLivePagePlaceholder';
import { TeammatePresencePlaceholder } from './TeammatePresencePlaceholder';

export const TeammatePresenceLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TeammatePresence" */ './TeammatePresence'))
			.TeammatePresence,
	loading: ({ isEditorActionBarSSREnabled, isLivePage }) => {
		if (isEditorActionBarSSREnabled) {
			if (isLivePage) {
				return <TeammatePresenceLivePagePlaceholder />;
			} else {
				return <TeammatePresencePlaceholder />;
			}
		} else {
			return null;
		}
	},
});
