import { LoadableLazy, LoadableAfterPaint } from '@confluence/loadable';

export const LivePagesInProductPromptModal = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LivePagesInProductPromptModal" */
				'./in-product-prompt/LivePagesInProductPromptModal'
			)
		).LivePagesInProductPromptModal,
});

export const LivePagesInProductPromptBanner = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LivePagesInProductPromptBanner" */
				'./in-product-prompt/LivePagesInProductPromptBanner'
			)
		).LivePagesInProductPromptBanner,
});

export const ConvertToLivePageDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ConvertToLivePageDialogLoadable" */ './convert-to-live-page-dialog/ConvertToLivePageDialog'
			)
		).ConvertToLivePageDialog,
});

export const ViewPageDistinctionBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ViewPageDistinctionBanner" */ './ViewPageDistinctionBanner'
			)
		).ViewPageDistinctionBanner,
});

export const EditorDistinctionBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorDistinctionBanner" */ './EditorDistinctionBanner'
			)
		).EditorDistinctionBanner,
});

export { useViewPageDistinctionBannerStore } from './ViewPageDistinctionBannerStore';
export { BANNER_HEIGHT as VIEW_PAGE_DISTINCTION_BANNER_HEIGHT } from './ViewPageDistinctionBanner';
